import React, { useEffect, useMemo, useState } from 'react';
import { DataFrame, FieldType, StandardEditorProps } from '@grafana/data';
import { Button, Cascader, CascaderOption, Collapse, Field } from '@grafana/ui';

import { TraceItem } from './TraceItem';
import { TraceItemType, TraceItemTracker, MappingType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_TRACE } from 'components/defaults';
import { getAxisKeysByPlotType } from 'components/editorHelper';
import { prepareStringFields, prepareFields } from 'data/utils';
import { PlotlyOptions } from 'components/types';

interface Props extends StandardEditorProps<PlotlyOptions["tracesConfig"]> { }

export const TracesEditor: React.FC<Props> = ({ onChange, value, context }) => {
  const tracesConfig = value;
  const [tracker, _setTracker] = useState((): TraceItemTracker[] => {
    if (!tracesConfig.traces) {
      const empty: TraceItemTracker[] = [];
      return empty;
    }
    const items: TraceItemTracker[] = [];
    tracesConfig.traces.forEach((value: TraceItemType, index: number) => {
      items[index] = {
        trace: value,
        order: index,
        ID: uuidv4(),
      };
    });
    return items;
  });

  const setTracker = (v: TraceItemTracker[]) => {
    _setTracker(v);
    // update the panel config (only the traces themselves, not the tracker)
    const allTraces: TraceItemType[] = [];
    v.forEach((element) => {
      allTraces.push(element.trace);
    });
    const _tracesConfig = {
      ...tracesConfig,
      traces: allTraces,
    };
    onChange(_tracesConfig as any);
  };

  // tracks trace card collapse state
  const [isOpen, setIsOpen] = useState((): boolean[] => {
    if (!tracker) {
      const empty: boolean[] = [];
      return empty;
    }
    let size = tracker.length;
    const openStates: boolean[] = [];
    while (size--) {
      openStates[size] = false;
    }
    return openStates;
  });

  // generic move
  const arrayMove = (arr: any, oldIndex: number, newIndex: number) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  };

  const moveDown = (index: number) => {
    if (index !== tracker.length - 1) {
      arrayMove(tracker, index, index + 1);
      // reorder
      for (let i = 0; i < tracker.length; i++) {
        tracker[i].order = i;
        tracker[i].trace.order = i;
      }
      setTracker([...tracker]);
    }
  };

  const moveUp = (index: number) => {
    if (index > 0) {
      arrayMove(tracker, index, index - 1);
      // reorder
      for (let i = 0; i < tracker.length; i++) {
        tracker[i].order = i;
        tracker[i].trace.order = i;
      }
      setTracker([...tracker]);
    }
  };

  const createDuplicate = (index: number) => {
    const original = tracker[index].trace;
    const order = tracker.length;
    const aTrace: TraceItemType = {
      ...original,
      name: `${original.name} Copy`,
      order: order,
    };
    const aTracker: TraceItemTracker = {
      trace: aTrace,
      order: order,
      ID: uuidv4(),
    };
    setTracker([...tracker, aTracker]);
    setIsOpen([...isOpen, true]);
  };

  const updateTrace = (order: number, value: TraceItemType) => {
    // find the Trace by the order
    const allTraces = [...tracker];
    let indexToUpdate = 0;
    for (let i = 0; i < allTraces.length; i++) {
      if (allTraces[i].order === order) {
        indexToUpdate = i;
        break;
      }
    }
    allTraces[indexToUpdate].trace = value;
    setTracker([...allTraces]);
  };

  const removeTrace = (order: number) => {
    // find the Trace by the order
    const allTraces = [...tracker];
    let removeIndex = 0;
    for (let i = 0; i < allTraces.length; i++) {
      if (allTraces[i].order === order) {
        removeIndex = i;
        break;
      }
    }
    allTraces.splice(removeIndex, 1);
    // reorder
    for (let i = 0; i < allTraces.length; i++) {
      allTraces[i].order = i;
    }
    setTracker([...allTraces]);
  };

  const resetToDefaultTrace = () => {
    const aTracker: TraceItemTracker = {
      trace: { ...DEFAULT_TRACE },
      order: 0,
      ID: uuidv4(),
    };
    setTracker([aTracker]);
  };

  const toggleOpener = (index: number) => {
    const toggleState = [...isOpen];
    toggleState[index] = !toggleState[index];
    setIsOpen([...toggleState]);
  };

  const addItem = () => {
    const order = tracker.length;
    const aTrace: TraceItemType = {
      ...DEFAULT_TRACE,
      name: `Trace-${order + 1}`,
      order: order,
    };
    const aTracker: TraceItemTracker = {
      trace: aTrace,
      order: order,
      ID: uuidv4(),
    };
    setTracker([...tracker, aTracker]);
    // add an opener also
    setIsOpen([...isOpen, true]);
  };

  const setPartitionBy = (value: string) => {
    const traceConfig = {
      ...tracesConfig,
      partitionBy: value,
    };
    onChange(traceConfig as any);
  }


  const axisKeys = useMemo(() => {
    console.log('TraceEditor: axisKeys updated');
    return getAxisKeysByPlotType(context.options.cfg.settings.type);
  }, [context.options.cfg.settings.type]);

  useEffect(() => {
    updateMappingsAccordingToPlotType(axisKeys, tracker, setTracker);
  }, [axisKeys]);

  const stringMetricHints = useMemo(() => computeStringMetricHints(context.data), [context.data]);
  const [metricHints, fieldTypeMap] = useMemo(() => computeMetricHints(context.data, tracesConfig.partitionBy), [context.data, tracesConfig.partitionBy]);

  return (
    <>
      <Field
        label={`Partition by`}
        key={`partition-by-mapping`}
        style={{ minWidth: '175px' }}
      >
        <Cascader
          key={stringMetricHints.toString()}
          initialValue={tracesConfig.partitionBy}
          options={stringMetricHints}
          onSelect={(val: string) => setPartitionBy(val)}
        />
      </Field>
      <Field>
        <Button fill="solid" variant="primary" icon="plus" onClick={addItem}>
          Add Trace
        </Button>
      </Field>
      <Field>
        <Button fill="solid" variant="destructive" icon="trash-alt" onClick={resetToDefaultTrace}>
          Reset
        </Button>
      </Field>
      {tracker &&
        tracker.map((item: TraceItemTracker, index: number) => {
          return (
            <Collapse
              key={`collapse-item-index-${item.ID}`}
              label={item.trace.name}
              isOpen={isOpen[index]}
              onToggle={() => toggleOpener(index)}
              collapsible
            >
              <TraceItem
                key={`trace-item-index-${item.ID}`}
                ID={item.ID}
                trace={item.trace}
                setter={updateTrace}
                remover={removeTrace}
                moveUp={moveUp}
                moveDown={moveDown}
                createDuplicate={createDuplicate}
                context={context}
                metricHints={metricHints}
                stringMetricHints={stringMetricHints}
                fieldTypeMap={fieldTypeMap}
              />
            </Collapse>
          );
        })}
    </>
  );
};
function updateMappingsAccordingToPlotType(
  axisKeys: string[],
  tracker: TraceItemTracker[],
  setTracker: (v: TraceItemTracker[]) => void
) {
  const mKeys = axisKeys.concat(['color', 'size', 'hovertext', 'groupBy']) as Array<keyof MappingType>;
  const updatedTracker = tracker.map((aTracker) => {
    const trace = aTracker.trace;
    const mappings = {} as MappingType;
    mKeys.forEach((key) => {
      if (trace.mapping[key] === undefined) {
        mappings[key] = ['', ''];
      } else {
        mappings[key] = trace.mapping[key];
      }
    });
    return { ...aTracker, trace: { ...trace, mapping: mappings } };
  });
  setTracker(updatedTracker);
}

function computeStringMetricHints(data: DataFrame[]): CascaderOption[] {
  let hints: CascaderOption[] = [{ value: '', label: '-' }];
  if (data) {
    const fields = prepareStringFields(data);
    if (fields) {
      for (const aField of fields) {
        hints.push({
          label: aField.name,
          value: aField.name,
        });
      }
    }
  }
  return hints;
}

function computeMetricHints(data: any, partitionBy: string): [CascaderOption[], Map<string, FieldType>] {
  let hints: CascaderOption[] = [{ value: '', label: '-' }];
  const datatypeMap = new Map<string, FieldType>();
  if (data) {
    const fields = prepareFields(data, partitionBy);
    if (fields) {
      // iterate over fields, get all number or time types and provide as hints
      for (const aField of fields) {
        hints.push({
          label: aField.name,
          value: aField.name,
        });
        datatypeMap.set(aField.name, aField.type);
      }
    }
  }
  return [hints, datatypeMap];
}
