import React, { useEffect, useState } from 'react';
import { FieldType, StandardEditorProps } from '@grafana/data';
import { Collapse } from '@grafana/ui';

import { AxisItem } from './AxisItem';
import { AxisEditorSettings, AxisItemType } from './types';
import { getAxisKeysByPlotType } from 'components/editorHelper';
import { DEFAULT_AXIS } from 'components/defaults';
import { MappingType, TraceItemType } from 'components/traces/types';

interface Props extends StandardEditorProps<string | string[] | null, AxisEditorSettings> { }

export const AxisEditor: React.FC<Props> = ({ context, onChange }) => {
  const [tracker, _setTracker] = useState((): AxisItemType[] => {
    const axis = context.options.axisConfig.axis;
    if (!axis) {
      const empty: AxisItemType[] = [];
      return empty;
    }
    const items: AxisItemType[] = [];
    axis.forEach((value: AxisItemType, index: number) => {
      items[index] = value;
    });
    return items;
  });

  const setTracker = (v: AxisItemType[]) => {
    _setTracker(v);
    // update the panel config (only the axis themselves, not the tracker)
    const allAxis: AxisItemType[] = [];
    v.forEach((element) => {
      allAxis.push(element);
    });
    const axisConfig = {
      axis: allAxis,
    };
    onChange(axisConfig as any);
  };

  // tracks axis card collapse state
  const [isOpen, setIsOpen] = useState((): boolean[] => {
    if (!tracker) {
      const empty: boolean[] = [];
      return empty;
    }
    let size = tracker.length;
    const openStates: boolean[] = [];
    while (size--) {
      openStates[size] = false;
    }
    return openStates;
  });

  const updateAxis = (value: AxisItemType) => {
    const index = tracker.findIndex((item: AxisItemType) => {
      return item.ID === value.ID;
    });
    if (index !== -1) {
      tracker[index] = value;
      setTracker([...tracker]);
    }
  };

  const toggleOpener = (index: number) => {
    const toggleState = [...isOpen];
    toggleState[index] = !toggleState[index];
    setIsOpen([...toggleState]);
  };

  useEffect(() => {
    const axisKeys = getAxisKeysByPlotType(context.options.cfg.settings.type);
    if (!tracker) {
      setTracker([]);
    }
    const items: AxisItemType[] = [];
    axisKeys.forEach((key: string) => {
      const trackerIndex = tracker.findIndex((axisItem: AxisItemType) => {
        return axisItem.ID === key;
      });
      if (trackerIndex === -1) {
        items.push(DEFAULT_AXIS.get(key)!);
      } else {
        items.push({ ...tracker[trackerIndex] });
      }
    });
    // better behaviour could be possible by using useDeferredValue()-hook instead of timeout once grafana updated the react version.
    setTimeout(() => setTracker([...items]), 100);
  }, [context.options.cfg.settings.type]);

  useEffect(() => {
    const allAxis = [...tracker];
    tracker.forEach((axis, axisIdx) => {
      let isDate = false;
      const traces: TraceItemType[] = context.options.tracesConfig.traces;
      traces.forEach((trace) => {
        if (trace.mapping[axis.ID as keyof MappingType]?.at(1) === FieldType.time) {
          isDate = true;
        }
        if (context.options.cfg.settings.type === 'box') {
          allAxis[axisIdx] = { ...axis, layout: { ...axis.layout, type: '-' } };
        }
        if (isDate) {
          allAxis[axisIdx] = { ...axis, layout: { ...axis.layout, type: 'date' } };
        }
      });
      setTracker(allAxis);
    });
  }, [context.options.tracesConfig.traces, context.options.cfg.settings.type]);

  return (
    <>
      {tracker &&
        tracker.map((item: AxisItemType, index: number) => {
          return (
            <Collapse
              key={`collapse-item-id-${item.ID}`}
              label={item.label}
              isOpen={isOpen[index]}
              onToggle={() => toggleOpener(index)}
              collapsible
            >
              <AxisItem key={`axis-item-id-${item.ID}`} axis={item} setter={updateAxis} context={context} />
            </Collapse>
          );
        })}
    </>
  );
};
