import React, { useMemo } from 'react';
import { DataFrame, dateTimeParse, Field, FieldType, InterpolateFunction, PanelProps } from '@grafana/data';
import { PlotlyOptions, SettingsOptions } from 'components/types';

import Plot from 'react-plotly.js';
import { prepareFields, prepareStringFields } from 'data/utils';
import { MappingType, TraceItemType } from './traces/types';
import { getAxisKeysByPlotType } from './editorHelper';
import { AxisItemType, AxisLayoutType } from './axis/types';
import { PlotRelayoutEvent } from 'plotly.js';

interface Props extends PanelProps<PlotlyOptions> { }

export const PlotlyPanel: React.FC<Props> = ({
  options,
  data,
  width,
  height,
  timeZone,
  onChangeTimeRange,
  replaceVariables,
}) => {

  const manageLayout = (inputOptions: PlotlyOptions, width: number, height: number): Partial<Plotly.Layout> => {
    const axisKeys = getAxisKeysByPlotType(inputOptions.cfg.settings.type);
    const axisConfigs = getAxisConfigsByKeys(axisKeys, inputOptions, replaceVariables);
    const template = createLayoutTemplate(inputOptions, width, height);
    const layout = addPlottypeSpecificProperties(inputOptions, template, axisConfigs);
    console.log('manageLayout() executed: ', layout);
    return layout;
  };

  const manageConfig = (inputSettings: SettingsOptions) => {
    const options = createOptionsTemplate(inputSettings);
    console.log('manageConfig() executed: ', options);
    return options;
  };

  function manageData(options: PlotlyOptions, inputSeries: DataFrame[], timeZone: string): Plotly.Data[] {
    const fields = prepareFields(inputSeries, options.tracesConfig.partitionBy, timeZone);

    if (fields.length < 1) {
      console.error('No Graphable Fields available');
      return [];
    }

    const stringFields = prepareStringFields(inputSeries);
    const allFields = stringFields ? fields.concat(stringFields) : fields;

    const mappedData: Array<Map<string, Field>> = getMappedData(options, allFields);
    const result = createDataTemplates(options, mappedData, timeZone);

    console.log('manageData() executed: ', result);
    return result;
  }


  const plotlyData = useMemo(() => manageData(options, data.series, timeZone), [options, data, timeZone]);
  const plotlyLayout = useMemo(
    () => manageLayout(options, width, height),
    [options, width, height, replaceVariables('$__from'), replaceVariables('$__to')]
  );
  const plotlyConfig = useMemo(() => manageConfig(options.cfg.settings), [options.cfg.settings]);

  return (
    <Plot
      data={plotlyData}
      layout={plotlyLayout}
      config={plotlyConfig}
      onRelayout={(event) => updateTimeRange(event, options, onChangeTimeRange)}
    />
  );
};

function updateTimeRange(event: Readonly<PlotRelayoutEvent>, options: PlotlyOptions, onChangeTimeRange: any) {
  if (options.cfg.settings.updateDashboardTimeRange) {
    if (options.cfg.settings.type === 'scatter' || options.cfg.settings.type === 'scattergl') {
      const xaxis = options.axisConfig.axis.find((axis) => axis.ID === 'x');
      const yaxis = options.axisConfig.axis.find((axis) => axis.ID === 'y');
      let range;
      if (xaxis?.layout.type === 'date' && event['xaxis.range[0]'] && event['xaxis.autorange'] !== true) {
        range = { from: event['xaxis.range[0]'], to: event['xaxis.range[1]'] };
      }
      if (yaxis?.layout.type === 'date' && event['yaxis.range[0]'] && event['yaxis.autorange'] !== true) {
        range = { from: event['yaxis.range[0]'], to: event['yaxis.range[1]'] };
      }
      if (range) {
        onChangeTimeRange(range);
      }
    }
  }
}

function createDataTemplates(options: PlotlyOptions, mappedData: Array<Map<string, Field>>, timeZone: string) {
  const result: Plotly.Data[] = [];
  options.tracesConfig.traces.map((trace, traceIdx) => {
    const hovertext = getHovertext(mappedData, traceIdx);
    // const hovertext = mappedData.at(traceIdx)?.get('hovertext')?.values.toArray();
    const markerColor = getMarkerColor(trace, mappedData, traceIdx);
    const markerSize = getMarkerSize(trace, mappedData, traceIdx);
    const transforms = getTransforms(trace, mappedData, traceIdx);
    const dataset: any = createDataset(
      trace,
      mappedData,
      traceIdx,
      options,
      markerColor,
      markerSize,
      hovertext,
      transforms,
      timeZone
    );
    result.push(dataset);
  });
  return result;
}

function createDataset(
  trace: TraceItemType,
  mappedData: any[],
  traceIdx: number,
  options: PlotlyOptions,
  markerColor: any,
  markerSize: any,
  hovertext: any,
  transforms: any,
  timeZone: string
): any {
  const plotType = options.cfg.settings.type;
  let dataset: any = {
    visible: trace.visible,
    name: trace.name,
    type: options.cfg.settings.type,
    transforms: transforms,
  }

  switch (plotType) {
    case ('box'):
      dataset = {
        ...dataset,
        boxmean: true,
        boxpoints: trace.settings.boxpoints,
        marker: trace.settings.boxAutoColor ? {} : { color: trace.settings.marker.color },
      }
      break;
    default:
      dataset = {
        ...dataset,
        mode: trace.settings.mode,
        marker: {
          autocolorscale: false,
          colorscale: trace.settings.marker.colorscale,
          color: markerColor,
          colorbar: {
            orientation: 'v',
          },
          showscale: trace.settings.marker.showscale,
          size: markerSize,
          sizemin: 0,
          sizeref: 1,
          symbol: trace.settings.marker.symbol,
          line: {
            width: 0,
          },
        },
        line: {
          color: trace.settings.line.color,
          dash: trace.settings.line.dash,
          shape: trace.settings.line.shape,
          width: trace.settings.line.width,
        },
        hovertext: hovertext,
      }
  }
  return addAxisDataAndHovertemplate(options, dataset, mappedData, traceIdx, timeZone);
}

function addAxisDataAndHovertemplate(
  options: PlotlyOptions,
  dataset: any,
  mappedData: Array<Map<string, Field>>,
  traceIdx: number,
  timeZone: string
) {
  const extendedDataset: any = { ...dataset };
  let hovertemplate = '';
  options.axisConfig.axis.forEach((axis) => {
    const field = mappedData.at(traceIdx)!.get(axis.ID);
    let values = field?.values;
    if (field?.type === FieldType.time) {
      /* Time values are parsed to UTC ISO-Strings. Note, that they are saved as UTC time but actually the timezone is according to the users setting and
  the timezone offset got added in prepareGraphableFields() already.
  Unfortunatlely this hack is neede to be able to show the datetimes for axes and hovertext in the same format, which is set by the users dashboard.*/
      values = parseValuesToUtcISOString(field);
    }
    extendedDataset[axis.ID] = values;
    hovertemplate = addLineToHovertemplate(field, hovertemplate, axis.ID, timeZone);
  });
  hovertemplate = addHoovertextLineToHovertemplate(hovertemplate, mappedData, traceIdx, timeZone);
  extendedDataset['hovertemplate'] = hovertemplate;
  return extendedDataset;
}

function addHoovertextLineToHovertemplate(
  hovertemplate: string,
  mappedData: Array<Map<string, Field>>,
  traceIdx: number,
  timeZone: string
) {
  hovertemplate = addLineToHovertemplate(
    mappedData.at(traceIdx)!.get('hovertext'),
    hovertemplate,
    'hovertext',
    timeZone
  );
  return hovertemplate;
}

function addLineToHovertemplate(field: Field | undefined, hovertemplate: string, key: string, timeZone: string) {
  const fieldType: FieldType | undefined = field?.type;
  // const timeZoneUserfriendly: string | undefined = timeZoneFormatUserFriendly(timeZone);
  if (fieldType) {
    switch (fieldType) {
      case FieldType.number:
        hovertemplate += field?.name + ': %{' + key + ':.2f}<br>';
        break;
      case FieldType.time:
        // Note: Don't add timezone offset to the format. It would always show time as UTC time, which might be incorrect
        hovertemplate += field?.name + ': %{' + key + '|%Y-%m-%d %H:%M:%S}<br>';
        // hovertemplate += field?.name + ': %{' + key + '|%Y-%m-%d %H:%M:%S}' + ' (' + timeZoneUserfriendly + ')<br>';
        break;
      default:
        hovertemplate += field?.name + ': %{' + key + '}<br>';
    }
  }
  return hovertemplate;
}

function getMarkerSize(trace: TraceItemType, mappedData: Array<Map<string, Field>>, traceIdx: number) {
  return trace.settings.size_option === 'variable'
    ? mappedData.at(traceIdx)!.get('size')?.values
    : trace.settings.marker.size;
}

function getMarkerColor(trace: TraceItemType, mappedData: Array<Map<string, Field>>, traceIdx: number) {
  return trace.settings.color_option === 'ramp'
    ? mappedData.at(traceIdx)!.get('color')?.values
    : trace.settings.marker.color;
}

function getTransforms(trace: TraceItemType, mappedData: Array<Map<string, Field>>, traceIdx: number) {
  const transforms = mappedData.at(traceIdx)!.get('groupBy') ? [{
    type: 'groupby',
    groups: mappedData.at(traceIdx)!.get('groupBy')?.values
  }] : undefined
  return transforms;
}

function getHovertext(mappedData: Array<Map<string, Field>>, traceIdx: number) {
  const field = mappedData.at(traceIdx)!.get('hovertext');
  /* 
  Time values are parsed to UTC ISO-Strings. Note, that they are saved as UTC time but actually the timezone is according to the users setting and
   the timezone offset got added in prepareGraphableFields() already.
   Unfortunatlely this hack is neede to be able to show the datetimes for axes and hovertext in the same format, which is set by the users dashboard.
   */
  const values = field?.type === FieldType.time ? parseValuesToUtcISOString(field) : field?.values
  return values;
}

function parseValuesToUtcISOString(field: Field) {
  return field.values.map((val) => dateTimeParse(val, { timeZone: 'utc' }).toISOString());
}

function getMappedData(options: PlotlyOptions, fields: Field[]) {
  let mappedData: Array<Map<string, Field>> = [];
  options.tracesConfig.traces.forEach((trace, traceIdx) => {
    let traceMappings: Map<string, Field> = new Map();
    options.axisConfig.axis.forEach((axis, axisIdx) => {
      const mappingEntry = trace.mapping[axis.ID as keyof MappingType]?.at(0);
      const aMatch = fields?.find((aField: Field) => {
        return mappingEntry && aField.name === mappingEntry;
      });
      if (aMatch !== undefined) {
        traceMappings.set(axis.ID, aMatch);
        // console.log('matched! traceIdx: ', traceIdx, ', axisID: ', axis.ID, ', field.name: ', aMatch.name);
      }
    });
    const otherKeys = ['size', 'color', 'hovertext', 'groupBy'] as Array<keyof MappingType>;
    otherKeys.forEach((key) => {
      const aMatch = fields?.find((aField) => {
        const mappingEntry = trace.mapping[key]?.at(0);
        return mappingEntry && aField.name === mappingEntry;
      });
      if (aMatch !== undefined) {
        traceMappings.set(key, aMatch);
        // console.log('matched! traceIdx: ', traceIdx, ', key: ', key, ', field.name: ', aMatch.name);
      }
    });
    mappedData.push(traceMappings);
  });
  return mappedData;
}

function createOptionsTemplate(inputSettings: SettingsOptions) {
  return {
    displayModeBar: inputSettings.displayModeBar,
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'select2d',
      'lasso2d',
      'hoverCompareCartesian',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'orbitRotation',
      'resetCameraLastSave3d',
    ] as unknown as Plotly.ModeBarDefaultButtons[],
    responsive: true,
  };
}

function createLayoutTemplate(inputOptions: PlotlyOptions, width: number, height: number) {
  const inputLayout = inputOptions.cfg.layout;
  return {
    showlegend: inputLayout.showlegend,
    legend: inputLayout.legend,
    width: width,
    height: height,
    margin: {
      b: 50,
      l: 60,
      r: 30,
      t: 0,
      pad: 20,
    },
    font: {
      family: '"Open Sans", Helvetica, Arial, sans-serif',
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    hovermode: 'closest' as false | 'closest' | 'x' | 'y' | 'x unified' | 'y unified' | undefined,
    dragmode: inputLayout.dragmode,
    shapes: getShapes(inputOptions)
  };
}

function getShapes(plotlyOptions: PlotlyOptions) {
  if (plotlyOptions.cfg.settings.type === 'scatter' || plotlyOptions.cfg.settings.type === 'scattergl') {

    const shapes = plotlyOptions.shapesConfig?.shapes;
    const outputShapes: any = shapes?.map((item) => {
      const basics = {
        type: item.type,
        visible: item.visible,
        opacity: item.opacity,
        layer: item.layer,
        line: {
          color: item.lineColor,
          width: item.lineWidth,
        }
      }
      switch (item.type) {
        case "circle":
          return {
            ...basics,
            x0: item.circle.Xc - item.circle.radius,
            y0: item.circle.Yc - item.circle.radius,
            x1: item.circle.Xc + item.circle.radius,
            y1: item.circle.Yc + item.circle.radius,
            fillcolor: item.fillColor,
          }
        case "rect":
          return {
            ...basics,
            x0: item.x0,
            y0: item.y0,
            x1: item.x1,
            y1: item.y1,
            fillcolor: item.fillColor,
          }
        case "line":
          return {
            ...basics,
            x0: item.x0,
            y0: item.y0,
            x1: item.x1,
            y1: item.y1,
          }
        default:
          return {}
      }
    })
    return outputShapes
  }
}

function getAxisConfigsByKeys(axisKeys: string[], inputOptions: PlotlyOptions, replaceVariables: InterpolateFunction) {
  const axisConfigs = new Map<string, AxisLayoutType>();
  axisKeys.map((key) => {
    const conf = inputOptions.axisConfig.axis.find((item) => item.ID === key);
    const config = manageRange(conf, replaceVariables);
    axisConfigs.set(key, config?.layout!);
  });
  return axisConfigs;
}

function addPlottypeSpecificProperties(
  inputOptions: PlotlyOptions,
  template: {
    showlegend: boolean;
    legend: { orientation: 'v' | 'h' };
    width: number;
    height: number;
    margin: { b: number; l: number; r: number; t: number; pad: number };
    font: { family: string };
    paper_bgcolor: string;
    plot_bgcolor: string;
    hovermode: false | 'closest' | 'x' | 'y' | 'x unified' | 'y unified' | undefined;
    dragmode: 'zoom' | 'lasso' | 'pan' | 'select';
  },
  axisConfigs: Map<string, AxisLayoutType>
) {
  let layout: any;
  switch (inputOptions.cfg.settings.type) {
    case 'scatter3d':
      layout = {
        ...template,
        scene: {
          xaxis: { ...axisConfigs.get('x') },
          yaxis: { ...axisConfigs.get('y') },
          zaxis: { ...axisConfigs.get('z') },
        },
      };
      break;
    case 'scatterpolar':
      layout = {
        ...template,
        polar: {
          angularaxis: { ...axisConfigs.get('theta') },
          radialaxis: { ...axisConfigs.get('r') },
          bgcolor: 'transparent',
        },
      };
      break;
    case 'scatter':
    case 'scattergl':
      if (inputOptions.cfg.layout.lockAxis) {
        layout = {
          ...template,
          xaxis: { ...axisConfigs.get('x'), constrain: 'domain' },
          yaxis: { ...axisConfigs.get('y'), scaleanchor: 'x' },
        };
      } else {
        layout = {
          ...template,
          xaxis: { ...axisConfigs.get('x') },
          yaxis: { ...axisConfigs.get('y') },
        };
      }
      break;
    default:
      layout = {
        ...template,
        xaxis: { ...axisConfigs.get('x') },
        yaxis: { ...axisConfigs.get('y') },
      };
  }
  return layout;
}
function manageRange(conf: AxisItemType | undefined, replaceVariables: InterpolateFunction): AxisItemType | undefined {
  if (conf) {
    let range;
    switch (conf.layout.dateRangeType) {
      case 'dashboard':
        range = [Number(replaceVariables('$__from')), Number(replaceVariables('$__to'))];
        break;
      case 'data':
      case 'manual':
      default:
        range = conf.layout.range;
    }
    return { ...conf, layout: { ...conf.layout, range: range } };
  } else {
    return undefined;
  }
}
