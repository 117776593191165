import { FieldType, SelectableValue } from '@grafana/data';
import { CascaderOption } from '@grafana/ui';

export type MappingType = {
  x?: MappingValueType;
  y?: MappingValueType;
  z?: MappingValueType;
  r?: MappingValueType;
  theta?: MappingValueType;
  color?: MappingValueType;
  size?: MappingValueType;
  hovertext?: MappingValueType;
  groupBy?: MappingValueType;
};

export type MappingValueType = [string, FieldType | ''];
export type TraceModeType = 'markers' | 'lines' | 'markers+lines' | 'none';
export type BoxPointType = 'all' | false | 'suspectedoutliers' | 'outliers';

export interface TraceItemType {
  name: string;
  order: number;
  visible: boolean;
  mapping: MappingType;
  show: {
    lines: boolean;
    markers: boolean;
    extraHovertext: boolean;
  };
  settings: {
    mode: TraceModeType;
    line: {
      color: string;
      width: number;
      dash: string;
      shape: string;
    };
    marker: {
      size: number;
      symbol: string;
      color: string;
      colorscale: string;
      sizemin?: number;
      sizeref?: number;
      //opacity: number,
      line?: {
        color: string;
        width: number;
      };
      showscale: boolean;
      colorbar?: {
        orientation: 'v' | 'h';
        title: {
          text: string;
          side: 'right' | 'top' | 'bottom';
        };
      };
    };
    boxmean: boolean;
    boxpoints: BoxPointType;
    boxAutoColor: boolean;
    color_option: string;
    size_option: string;
  };
}

export interface TraceItemTracker {
  trace: TraceItemType;
  order: number;
  ID: string;
}

export interface TraceItemProps {
  trace: TraceItemType;
  ID: string;
  // enabled: boolean;
  setter: any;
  remover: any;
  moveUp: any;
  moveDown: any;
  createDuplicate: any;
  context: any;
  metricHints: CascaderOption[];
  stringMetricHints: CascaderOption[];
  fieldTypeMap: Map<string, FieldType>;
}

export interface TraceEditorSettings {
  traces: TraceItemType[];
}

export const MarkerSizeOptions: SelectableValue[] = [
  { value: 'fixed', label: 'Fixed' },
  { value: 'variable', label: 'Variable' },
];

export const MarkerColorOptions: SelectableValue[] = [
  { value: 'ramp', label: 'Ramp' },
  { value: 'solid', label: 'Solid' },
];

export const MarkerColorScaleOptions: SelectableValue[] = [
  { value: '', label: 'Default' },
  { value: 'Greys', label: 'Greys' },
  { value: 'YlGnBu', label: 'YlGnBu' },
  { value: 'Greens', label: 'Greens' },
  { value: 'YlOrRd', label: 'YlOrRd' },
  { value: 'Bluered', label: 'Bluered' },
  { value: 'RdBu', label: 'RdBu' },
  { value: 'Reds', label: 'Reds' },
  { value: 'Blues', label: 'Blues' },
  { value: 'Picnic', label: 'Picnic' },
  { value: 'Rainbow', label: 'Rainbow' },
  { value: 'Portland', label: 'Portland' },
  { value: 'Jet', label: 'Jet' },
  { value: 'Hot', label: 'Hot' },
  { value: 'Blackbody', label: 'Blackbody' },
  { value: 'Earth', label: 'Earth' },
  { value: 'Electric', label: 'Electric' },
  { value: 'Viridis', label: 'Viridis' },
  { value: 'Cividis', label: 'Cividis' },
];

export const LineShapeOptions: SelectableValue[] = [
  { value: 'linear', label: 'linear' },
  { value: 'spline', label: 'spline' },
  { value: 'vhv', label: 'vhv' },
  { value: 'hvh', label: 'hvh' },
  { value: 'vh', label: 'vh' },
  { value: 'hv', label: 'hv' },
];

export const BoxpointOptions: SelectableValue[] = [
  { value: 'outliers', label: 'Outliers' },
  { value: 'suspectedoutliers', label: 'Suspected Outliers' },
  { value: 'all', label: 'All' },
  { value: false, label: 'False' },
];

export const LineDashOptions: SelectableValue[] = [
  { value: 'solid', label: 'solid' },
  { value: 'dot', label: 'dot' },
  { value: 'dash', label: 'dash' },
  { value: 'longdash', label: 'longdash' },
  { value: 'dashdot', label: 'dashdot' },
  { value: 'longdashdot', label: 'longdashdot' },
];

export const SymbolOptions: SelectableValue[] = [
  { value: 'circle', label: 'circle' },
  { value: 'circle-open', label: 'circle-open' },
  { value: 'circle-dot', label: 'circle-dot' },
  { value: 'circle-open-dot', label: 'circle-open-dot' },
  { value: 'square', label: 'square' },
  { value: 'square-open', label: 'square-open' },
  { value: 'square-dot', label: 'square-dot' },
  { value: 'square-open-dot', label: 'square-open-dot' },
  { value: 'diamond', label: 'diamond' },
  { value: 'diamond-open', label: 'diamond-open' },
  { value: 'diamond-dot', label: 'diamond-dot' },
  { value: 'diamond-open-dot', label: 'diamond-open-dot' },
  { value: 'cross', label: 'cross' },
  { value: 'cross-open', label: 'cross-open' },
  { value: 'cross-dot', label: 'cross-dot' },
  { value: 'cross-open-dot', label: 'cross-open-dot' },
  { value: 'x', label: 'x' },
  { value: 'x-open', label: 'x-open' },
  { value: 'x-dot', label: 'x-dot' },
  { value: 'x-open-dot', label: 'x-open-dot' },
  { value: 'triangle-up', label: 'triangle-up' },
  { value: 'triangle-up-open', label: 'triangle-up-open' },
  { value: 'triangle-up-dot', label: 'triangle-up-dot' },
  { value: 'triangle-up-open-dot', label: 'triangle-up-open-dot' },
  { value: 'triangle-down', label: 'triangle-down' },
  { value: 'triangle-down-open', label: 'triangle-down-open' },
  { value: 'triangle-down-dot', label: 'triangle-down-dot' },
  { value: 'triangle-down-open-dot', label: 'triangle-down-open-dot' },
  { value: 'triangle-left', label: 'triangle-left' },
  { value: 'triangle-left-open', label: 'triangle-left-open' },
  { value: 'triangle-left-dot', label: 'triangle-left-dot' },
  { value: 'triangle-left-open-dot', label: 'triangle-left-open-dot' },
  { value: 'triangle-right', label: 'triangle-right' },
  { value: 'triangle-right-open', label: 'triangle-right-open' },
  { value: 'triangle-right-dot', label: 'triangle-right-dot' },
  { value: 'triangle-right-open-dot', label: 'triangle-right-open-dot' },
  { value: 'triangle-ne', label: 'triangle-ne' },
  { value: 'triangle-ne-open', label: 'triangle-ne-open' },
  { value: 'triangle-ne-dot', label: 'triangle-ne-dot' },
  { value: 'triangle-ne-open-dot', label: 'triangle-ne-open-dot' },
  { value: 'triangle-se', label: 'triangle-se' },
  { value: 'triangle-se-open', label: 'triangle-se-open' },
  { value: 'triangle-se-dot', label: 'triangle-se-dot' },
  { value: 'triangle-se-open-dot', label: 'triangle-se-open-dot' },
  { value: 'triangle-sw', label: 'triangle-sw' },
  { value: 'triangle-sw-open', label: 'triangle-sw-open' },
  { value: 'triangle-sw-dot', label: 'triangle-sw-dot' },
  { value: 'triangle-sw-open-dot', label: 'triangle-sw-open-dot' },
  { value: 'triangle-nw', label: 'triangle-nw' },
  { value: 'triangle-nw-open', label: 'triangle-nw-open' },
  { value: 'triangle-nw-dot', label: 'triangle-nw-dot' },
  { value: 'triangle-nw-open-dot', label: 'triangle-nw-open-dot' },
  { value: 'pentagon', label: 'pentagon' },
  { value: 'pentagon-open', label: 'pentagon-open' },
  { value: 'pentagon-dot', label: 'pentagon-dot' },
  { value: 'pentagon-open-dot', label: 'pentagon-open-dot' },
  { value: 'hexagon', label: 'hexagon' },
  { value: 'hexagon-open', label: 'hexagon-open' },
  { value: 'hexagon-dot', label: 'hexagon-dot' },
  { value: 'hexagon-open-dot', label: 'hexagon-open-dot' },
  { value: 'hexagon2', label: 'hexagon2' },
  { value: 'hexagon2-open', label: 'hexagon2-open' },
  { value: 'hexagon2-dot', label: 'hexagon2-dot' },
  { value: 'hexagon2-open-dot', label: 'hexagon2-open-dot' },
  { value: 'octagon', label: 'octagon' },
  { value: 'octagon-open', label: 'octagon-open' },
  { value: 'octagon-dot', label: 'octagon-dot' },
  { value: 'octagon-open-dot', label: 'octagon-open-dot' },
  { value: 'star', label: 'star' },
  { value: 'star-open', label: 'star-open' },
  { value: 'star-dot', label: 'star-dot' },
  { value: 'star-open-dot', label: 'star-open-dot' },
  { value: 'hexagram', label: 'hexagram' },
  { value: 'hexagram-open', label: 'hexagram-open' },
  { value: 'hexagram-dot', label: 'hexagram-dot' },
  { value: 'hexagram-open-dot', label: 'hexagram-open-dot' },
  { value: 'star-triangle-up', label: 'star-triangle-up' },
  { value: 'star-triangle-up-open', label: 'star-triangle-up-open' },
  { value: 'star-triangle-up-dot', label: 'star-triangle-up-dot' },
  { value: 'star-triangle-up-open-dot', label: 'star-triangle-up-open-dot' },
  { value: 'star-triangle-down', label: 'star-triangle-down' },
  { value: 'star-triangle-down-open', label: 'star-triangle-down-open' },
  { value: 'star-triangle-down-dot', label: 'star-triangle-down-dot' },
  { value: 'star-triangle-down-open-dot', label: 'star-triangle-down-open-dot' },
  { value: 'star-square', label: 'star-square' },
  { value: 'star-square-open', label: 'star-square-open' },
  { value: 'star-square-dot', label: 'star-square-dot' },
  { value: 'star-square-open-dot', label: 'star-square-open-dot' },
  { value: 'star-diamond', label: 'star-diamond' },
  { value: 'star-diamond-open', label: 'star-diamond-open' },
  { value: 'star-diamond-dot', label: 'star-diamond-dot' },
  { value: 'star-diamond-open-dot', label: 'star-diamond-open-dot' },
  { value: 'diamond-tall', label: 'diamond-tall' },
  { value: 'diamond-tall-open', label: 'diamond-tall-open' },
  { value: 'diamond-tall-dot', label: 'diamond-tall-dot' },
  { value: 'diamond-tall-open-dot', label: 'diamond-tall-open-dot' },
  { value: 'diamond-wide', label: 'diamond-wide' },
  { value: 'diamond-wide-open', label: 'diamond-wide-open' },
  { value: 'diamond-wide-dot', label: 'diamond-wide-dot' },
  { value: 'diamond-wide-open-dot', label: 'diamond-wide-open-dot' },
  { value: 'hourglass', label: 'hourglass' },
  { value: 'hourglass-open', label: 'hourglass-open' },
  { value: 'bowtie', label: 'bowtie' },
  { value: 'bowtie-open', label: 'bowtie-open' },
  { value: 'circle-cross', label: 'circle-cross' },
  { value: 'circle-cross-open', label: 'circle-cross-open' },
  { value: 'circle-x', label: 'circle-x' },
  { value: 'circle-x-open', label: 'circle-x-open' },
  { value: 'square-cross', label: 'square-cross' },
  { value: 'square-cross-open', label: 'square-cross-open' },
  { value: 'square-x', label: 'square-x' },
  { value: 'square-x-open', label: 'square-x-open' },
  { value: 'diamond-cross', label: 'diamond-cross' },
  { value: 'diamond-cross-open', label: 'diamond-cross-open' },
  { value: 'diamond-x', label: 'diamond-x' },
  { value: 'diamond-x-open', label: 'diamond-x-open' },
  { value: 'cross-thin', label: 'cross-thin' },
  { value: 'cross-thin-open', label: 'cross-thin-open' },
  { value: 'x-thin', label: 'x-thin' },
  { value: 'x-thin-open', label: 'x-thin-open' },
  { value: 'asterisk', label: 'asterisk' },
  { value: 'asterisk-open', label: 'asterisk-open' },
  { value: 'hash', label: 'hash' },
  { value: 'hash-open', label: 'hash-open' },
  { value: 'hash-dot', label: 'hash-dot' },
  { value: 'hash-open-dot', label: 'hash-open-dot' },
  { value: 'y-up', label: 'y-up' },
  { value: 'y-up-open', label: 'y-up-open' },
  { value: 'y-down', label: 'y-down' },
  { value: 'y-down-open', label: 'y-down-open' },
  { value: 'y-left', label: 'y-left' },
  { value: 'y-left-open', label: 'y-left-open' },
  { value: 'y-right', label: 'y-right' },
  { value: 'y-right-open', label: 'y-right-open' },
  { value: 'line-ew', label: 'line-ew' },
  { value: 'line-ew-open', label: 'line-ew-open' },
  { value: 'line-ns', label: 'line-ns' },
  { value: 'line-ns-open', label: 'line-ns-open' },
  { value: 'line-ne', label: 'line-ne' },
  { value: 'line-ne-open', label: 'line-ne-open' },
  { value: 'line-nw', label: 'line-nw' },
  { value: 'line-nw-open', label: 'line-nw-open' },
];
