import React from 'react';

import {
  Input,
  Select,
  Field,
  FieldSet,
  Card,
  IconButton,
  ColorPicker,
  Slider,
  RadioButtonGroup,
} from '@grafana/ui';
import {
  ShapeItemProps,
  ShapeItemType,
  ShapeLayerOptions,
  ShapeLayerType,
  ShapeTypeOptions,
  ShapeTypeType,
} from './types';

export const ShapeItem: React.FC<ShapeItemProps> = (props: ShapeItemProps) => {
  // const [shape, _setShape] = useState(props.shape);
  const setShape = (value: ShapeItemType) => {
    // _setShape(value);
    props.setter(props.shape.order, value);
  };

  const setShapeType = (value: ShapeTypeType) => {
    setShape({ ...props.shape, type: value })
  }

  const setRadius = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, circle: { ...props.shape.circle, radius: numVal } })
  }

  const setXc = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, circle: { ...props.shape.circle, Xc: numVal } })
  }

  const setYc = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, circle: { ...props.shape.circle, Yc: numVal } })
  }

  const setLineColor = (color: string) => {
    setShape({ ...props.shape, lineColor: color })
  }

  const setLineWidth = (value: string) => {
    const numVal = Number(value);
    setShape({ ...props.shape, lineWidth: numVal })
  }

  const setFillColor = (color: string) => {
    setShape({ ...props.shape, fillColor: color })
  }

  const setOpacity = (opacity: number) => {
    const numVal = Number(opacity);
    setShape({ ...props.shape, opacity: numVal })
  }

  const setLayer = (layer: ShapeLayerType) => {
    setShape({ ...props.shape, layer: layer })
  }

  const setX0 = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, x0: numVal })
  }

  const setY0 = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, y0: numVal })
  }

  const setX1 = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, x1: numVal })
  }

  const setY1 = (value: any) => {
    const numVal = Number(value);
    setShape({ ...props.shape, y1: numVal })
  }

  const moveUp = () => {
    props.moveUp(props.shape.order);
  };
  const moveDown = () => {
    props.moveDown(props.shape.order);
  };
  const createDuplicate = () => {
    props.createDuplicate(props.shape.order);
  };
  const removeItem = () => {
    props.remover(props.shape.order);
  };

  return (
    <ul>
      <li>
        <Card key={`shape-data-card-${props.ID}`}>
          <Card.Meta>
            <FieldSet>
              <Field label="Type">
                <Select
                  disabled={false}
                  menuShouldPortal={true}
                  value={props.shape.type}
                  onChange={(v) => {
                    setShapeType(v.value as ShapeTypeType);
                  }}
                  options={ShapeTypeOptions}
                />
              </Field>
              {props.shape.type === "circle" && getCircleOptions(props, setRadius, setXc, setYc)}
              {props.shape.type !== "circle" && getRectAndLineOptions(props, setX0, setY0, setX1, setY1)}
            </FieldSet>
          </Card.Meta>
        </Card>
      </li>
      <li>
        <Card key={`shape-appearance-card-${props.ID}`}>
          <Card.Meta>
            <FieldSet>
              {props.shape.type !== "line" &&
                <Field label="Fill Color">
                  <ColorPicker
                    color={props.shape.fillColor}
                    onChange={(color) => setFillColor(color)}
                    enableNamedColors={false}
                  />
                </Field>
              }
              <Field label="Line Color">
                <ColorPicker
                  color={props.shape.lineColor}
                  onChange={(color) => setLineColor(color)}
                  enableNamedColors={false}
                />
              </Field>
              <Field label="Line Width">
                <Input
                  type="number"
                  value={props.shape.lineWidth}
                  placeholder=""
                  onChange={(e: any) => setLineWidth(e.currentTarget.value)}
                />
              </Field>
              <Field label="Opacity" description="Set the opacity of line and shape. Use the color picker to choose the opacity for the line and the fill individually">
                <Slider
                  included
                  max={1}
                  min={0}
                  step={0.1}
                  orientation="horizontal"
                  value={props.shape.opacity}
                  onChange={(e: number) => setOpacity(e)}
                />
              </Field>
              <Field label="Layer" description="Place the shape below or above the plot and grid">
                <RadioButtonGroup
                  value={props.shape.layer}
                  onChange={setLayer}
                  options={ShapeLayerOptions}
                ></RadioButtonGroup>
              </Field>
            </FieldSet>
          </Card.Meta>
          {getSecondaryActions(moveUp, moveDown, props.shape, setShape, createDuplicate, props, removeItem)}
        </Card>
      </li>
    </ul>
  );
};

function getCircleOptions(props: ShapeItemProps, setRadius: (value: any) => void, setXc: (value: any) => void, setYc: (value: any) => void) {
  return <>
    <Field label="Radius">
      <Input
        type="number"
        value={props.shape.circle.radius}
        placeholder=""
        onChange={(e: any) => setRadius(e.currentTarget.value)}
      />
    </Field>
    <Field label="X-center">
      <Input
        type="number"
        value={props.shape.circle.Xc}
        placeholder="0"
        onChange={(e: any) => setXc(e.currentTarget.value)}
      />
    </Field>
    <Field label="Y-center">
      <Input
        type="number"
        value={props.shape.circle.Yc}
        placeholder="0"
        onChange={(e: any) => setYc(e.currentTarget.value)}
      />
    </Field>
  </>
}

function getRectAndLineOptions(props: ShapeItemProps, setX0: (value: any) => void, setY0: (value: any) => void, setX1: (value: any) => void, setY1: (value: any) => void) {
  return <>

    <Field label="X-start">
      <Input
        type="number"
        value={props.shape.x0}
        placeholder=""
        onChange={(e: any) => setX0(e.currentTarget.value)}
      />
    </Field>
    <Field label="Y-start">
      <Input
        type="number"
        value={props.shape.y0}
        placeholder=""
        onChange={(e: any) => setY0(e.currentTarget.value)}
      />
    </Field>
    <Field label="X-end">
      <Input
        type="number"
        value={props.shape.x1}
        placeholder=""
        onChange={(e: any) => setX1(e.currentTarget.value)}
      />
    </Field>
    <Field label="Y-end">
      <Input
        type="number"
        value={props.shape.y1}
        placeholder=""
        onChange={(e: any) => setY1(e.currentTarget.value)}
      />
    </Field>
  </>

}

function getSecondaryActions(
  moveUp: () => void,
  moveDown: () => void,
  shape: ShapeItemType,
  setShape: (value: ShapeItemType) => void,
  createDuplicate: () => void,
  props: ShapeItemProps,
  removeItem: () => void
) {
  return (
    <Card.SecondaryActions>
      <IconButton key="moveUp" name="arrow-up" tooltip="Move Up" onClick={moveUp} />
      <IconButton key="moveDown" name="arrow-down" tooltip="Move Down" onClick={moveDown} />
      <IconButton
        key="showShape"
        name={shape.visible ? 'eye' : 'eye-slash'}
        tooltip="Hide/Show Shape"
        onClick={() => setShape({ ...shape, visible: !shape.visible })}
      />
      <IconButton key="copyShape" name="copy" tooltip="Duplicate" onClick={createDuplicate} />
      <IconButton
        key="deleteShape"
        variant="destructive"
        name="trash-alt"
        tooltip="Delete Shape"
        onClick={removeItem}
      />
    </Card.SecondaryActions>
  );
}
