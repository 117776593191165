import React, { useState } from 'react';

import { Input, Select, Field, FieldSet, Switch, Card, RadioButtonGroup } from '@grafana/ui';
import {
  AxisItemProps,
  AxisItemType,
  AxisLayoutType,
  AxisType,
  AxisTypes,
  DateRangeType,
  DateRangeTypes,
  DirectionTypes,
  RangeTypes,
} from './types';
import { SelectableValue } from '@grafana/data';

export const AxisItem: React.FC<AxisItemProps> = (props: AxisItemProps) => {
  const getAxisType = (axisType: string) => {
    const keys = AxisTypes.keys();
    for (const aKey of keys) {
      if (AxisTypes[aKey].value === axisType) {
        return AxisTypes[aKey];
      }
    }
    // no match, return '-' (Auto) by default
    return AxisTypes[0];
  };
  const getRangeType = (rangeType: string) => {
    const keys = RangeTypes.keys();
    for (const aKey of keys) {
      if (RangeTypes[aKey].value === rangeType) {
        return RangeTypes[aKey];
      }
    }
    // no match, return normal by default
    return RangeTypes[0];
  };

  const [axis, _setAxis] = useState(props.axis);
  const [axisType, _setAxisType] = useState<SelectableValue<any> | undefined>(
    props.axis.layout.type ? getAxisType(props.axis.layout.type) : undefined
  );
  const [rangeType, setRangeType] = useState<SelectableValue<any> | undefined>(
    props.axis.layout.rangemode ? getRangeType(props.axis.layout.rangemode) : undefined
  );
  const [rangeMin, _setRangeMin] = useState(
    props.axis.layout.range?.at(0) === undefined ? undefined : props.axis.layout.range.at(0)
  );
  const [rangeMax, _setRangeMax] = useState(
    props.axis.layout.range?.at(1) === undefined ? undefined : props.axis.layout.range.at(1)
  );
  const [unit, _setUnit] = useState(props.axis.layout.ticksuffix);
  const [directionType, _setDirectionType] = useState(props.axis.layout.direction);

  const [dateRangeType, _setDateRangeType] = useState(() => {
    if (props.axis.layout.type === 'date') {
      return props.axis.layout.dateRangeType ? props.axis.layout.dateRangeType : 'dashboard';
    } else {
      return undefined;
    }
  });

  const setDateRangeType = (dateRangeType: DateRangeType) => {
    const axisLayout = { ...axis.layout, dateRangeType: dateRangeType };
    switch (dateRangeType) {
      case 'dashboard':
        axisLayout.autorange = false;
        break;
      case 'manual':
        axisLayout.autorange = false;
        break;
      default:
        axisLayout.autorange = true;
    }
    _setDateRangeType(dateRangeType);
    setAxisLayout({ ...axisLayout });
  };

  const setAxisType = (axisType: AxisType) => {
    const axisLayout = { ...axis.layout, type: axisType };
    if (axisType === 'date') {
      axisLayout.dateRangeType = 'dashboard';
      _setDateRangeType('dashboard');
    } else {
      axisLayout.dateRangeType = undefined;
      _setDateRangeType(undefined);
    }
    _setAxisType(AxisTypes.find((item) => item.value === axisType));
    setAxisLayout(axisLayout);
  };

  const setAxis = (value: AxisItemType) => {
    _setAxis(value);
    props.setter(value);
  };

  const setAxisLayout = (val: AxisLayoutType) => {
    setAxis({ ...axis, layout: val });
  };

  const setRangeMin = (val: any) => {
    const numVal = Number(val);
    _setRangeMin(numVal);
    setAxisLayout({ ...axis.layout, range: [numVal, axis.layout.range![1]] });
  };

  const setRangeMax = (val: any) => {
    const numVal = Number(val);
    _setRangeMax(numVal);
    setAxisLayout({ ...axis.layout, range: [axis.layout.range![0], numVal] });
  };

  const setUnit = (val: any) => {
    _setUnit(val);
    setAxisLayout({ ...axis.layout, ticksuffix: val });
  };

  const setDirectionType = (val: any) => {
    _setDirectionType(val);
    setAxisLayout({ ...axis.layout, direction: val });
  };

  const setTitle = (val: string) => {
    const value = val === '' ? undefined : val;
    setAxisLayout({ ...axis.layout, title: { ...axis.layout.title, text: value } });
  };

  const toggleShowgrid = () => {
    const currentState = axis.layout.showgrid;
    //setShowgrid(!currentState);
    setAxisLayout({ ...axis.layout, showgrid: !currentState });
  };

  const toggleZeroline = () => {
    const currentState = axis.layout.zeroline;
    setAxisLayout({ ...axis.layout, zeroline: !currentState });
  };

  const toggleAutorange = () => {
    const currentState = axis.layout.autorange;
    const range = currentState === true ? [0, 100] : undefined;
    setAxisLayout({ ...axis.layout, autorange: !currentState, range: range });
  };

  return (
    <Card key={`axis-card-${props.axis.ID}`}>
      <Card.Meta>
        <FieldSet>
          {axis.layout.title !== undefined && (
            <Field label="Axis Title">
              <Input
                label="Title"
                value={axis.layout.title?.text}
                placeholder="No Title"
                onChange={(e) => setTitle(e.currentTarget.value)}
              />
            </Field>
          )}
          {axis.layout.type !== undefined && (
            <Field label="Type">
              <Select
                menuShouldPortal={true}
                value={axisType}
                onChange={(v) => {
                  setAxisType(v.value);
                }}
                options={AxisTypes}
              />
            </Field>
          )}
          {axis.layout.type === 'date' && (
            <Field label="Date Range">
              <RadioButtonGroup
                value={dateRangeType}
                onChange={setDateRangeType}
                options={DateRangeTypes}
              ></RadioButtonGroup>
            </Field>
          )}
          {axis.layout.autorange !== undefined && axis.layout.type !== 'date' && (
            <Field label="Autorange">
              <Switch transparent={true} value={axis.layout.autorange} onChange={toggleAutorange}></Switch>
            </Field>
          )}
          {axis.layout.autorange === true && (
            <Field label="Rangemode">
              <Select
                menuShouldPortal={true}
                value={rangeType}
                onChange={(v) => {
                  setRangeType(v);
                  setAxisLayout({ ...axis.layout, rangemode: v.value });
                }}
                options={RangeTypes}
              />
            </Field>
          )}
          {axis.layout.autorange === false &&
            (axis.layout.type !== 'date' ||
              (axis.layout.type === 'date' && axis.layout.dateRangeType === 'manual')) && (
              <>
                <Field label="Min">
                  <Input
                    type="number"
                    value={rangeMin}
                    placeholder="min"
                    onChange={(e: any) => setRangeMin(e.currentTarget.value)}
                  />
                </Field>
                <Field label="Max">
                  <Input
                    type="number"
                    value={rangeMax}
                    placeholder="Max"
                    onChange={(e: any) => setRangeMax(e.currentTarget.value)}
                  />
                </Field>
              </>
            )}
          {axis.layout.ticksuffix !== undefined && (
            <Field label="Unit">
              <Input value={unit} placeholder="Text" onChange={(e: any) => setUnit(e.currentTarget.value)} />
            </Field>
          )}
          {axis.layout.rotation !== undefined && (
            <Field label="Rotation">
              <Input
                type="number"
                label="Rotation"
                value={axis.layout.rotation}
                placeholder="degree"
                onChange={(e) => setAxisLayout({ ...axis.layout, rotation: Number(e.currentTarget.value) })}
              />
            </Field>
          )}
          {axis.layout.direction !== undefined && (
            <Field label="Direction" >
              <Select
                menuShouldPortal={true}
                value={directionType}
                onChange={(v) => {
                  setDirectionType(v);
                  setAxisLayout({ ...axis.layout, direction: v.value });
                }}
                options={DirectionTypes}
              />
            </Field>
          )}
          {axis.layout.showgrid !== undefined && (
            <Field label="Show Grid">
              <Switch transparent={true} value={axis.layout.showgrid} onChange={toggleShowgrid}></Switch>
            </Field>
          )}
          {axis.layout.zeroline !== undefined && (
            <Field label="Show Zeroline">
              <Switch transparent={true} value={axis.layout.zeroline} onChange={toggleZeroline}></Switch>
            </Field>
          )}
        </FieldSet>
      </Card.Meta>
    </Card>
  );
};
