import {
  DataFrame,
  Field,
  FieldType,
  getFrameDisplayName,
  getFieldDisplayName,
  dateTimeParse,
} from '@grafana/data';

/**
 * Returns empty array if there are no graphable fields
 */
export function prepareGraphableFields(series: DataFrame[], timeZone?: string): Field[] {
  if (!series?.length) {
    return [];
  }
  let copy: Field;

  let allFields: Field[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        /* ATTENTION:
        The offset of the timezone, set by the user, gets added manually here which make the altered data actually incorrect. Unfortunately this is needed
        to be able to show the datetime in the plot and hoverlabels according to the selected timezone */
        case FieldType.time:
          hasTimeField = true;
          const values = field.values.map((val) => {
            const grafanaDateUtcOffset = dateTimeParse(val, { timeZone }).utcOffset();
            const alteredDate = val + 60000 * grafanaDateUtcOffset;
            return alteredDate;
          });
          copy = {
            ...field,
            values: values,
            name: `${getFrameDisplayName(frame)}@time`,
          };
          fields.push(copy);
          break;
        case FieldType.number:
          hasValueField = true;
          copy = {
            ...field,
            name: getFieldDisplayName(field, frame),
          };
          fields.push(copy);
          break; // ok
        case FieldType.string:
          break; // ignored
        case FieldType.boolean:
          break; // ignored
      }
    }

    // add a index Field per DataFrame
    if (hasTimeField || hasValueField) {
      fields.push({
        name: `${getFrameDisplayName({ ...frame, fields })}@index`,
        type: FieldType.number,
        config: {},
        values: [...Array(frame.length).keys()],
      });
      allFields = allFields.concat(fields);
    }
  }

  if (allFields.length) {
    return allFields;
  }
  return [];
}

/**
 * Returns empty array if there are no string fields
 */
export function prepareStringFields(series: DataFrame[]): Field[] {
  if (!series?.length) {
    return [];
  }
  let copy: Field;

  let allFields: Field[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasStringField = false;

    for (const field of frame.fields) {
      switch (field.type) {
        case FieldType.string:
          hasStringField = true;
          copy = {
            ...field,
            name: getFieldDisplayName(field, frame),
          };
          fields.push(copy);
          break; // ok
        case FieldType.boolean:
          break; // ignored
      }
    }

    if (hasStringField) {
      allFields = allFields.concat(fields);
    }
  }

  if (allFields.length) {
    return allFields;
  }
  return [];
}


/**
 * 
 */
export function partitionField(graphableField: Field, partitionBy: Field): Field[] {
  if (partitionBy.type !== FieldType.string) {
    console.error('Only string fields can be used for partiotioning');
    return []
  }
  const graphableFieldArr: number[] = graphableField.values;
  const partitionByArr: string[] = partitionBy.values;

  const groupedData: { [key: string]: number[] } = {};

  // Loop through the 'graphableField' array.
  graphableFieldArr.forEach((value, index) => {
    // Get the value from the 'partitionBy' array that belongs to the current index in the 'graphableField' array.
    const partitionValue = partitionByArr[index];

    // Add the current value to the corresponding array in the 'groupedData' object, based on the value in 'partitionBy'.
    if (groupedData.hasOwnProperty(partitionValue)) {
      groupedData[partitionValue].push(value);
    } else {
      groupedData[partitionValue] = [value];
    }
  });
  // Convert the grouped data object into an array of Fields
  const groupedFields: Field[] = Object.entries(groupedData).map(([key, values]) => ({
    ...graphableField,
    name: `${graphableField.name} ${key}`,
    values: values,
  }));

  // Return the array of grouped fields
  return groupedFields;
}


/**
 * 
 */
export function prepareFields(series: DataFrame[], partitionBy?: string, timeZone?: string): Field[] {
  const graphableFields = prepareGraphableFields(series, timeZone);
  if (!partitionBy) {
    return graphableFields
  }
  const stringFields = prepareStringFields(series);
  const partitionByField = stringFields?.find((aField: Field) => {
    return aField.name === partitionBy;
  })
  let partitionedFields: Field[] = [];
  if (partitionByField) {
    graphableFields?.forEach((item, index) => {
      const pFields = partitionField(item, partitionByField)
      if (pFields) {
        partitionedFields = partitionedFields.concat(pFields)
      }
    })
  }
  return partitionedFields;
}




