import { SelectableValue } from '@grafana/data';

export const AxisTypes: SelectableValue[] = [
  { value: '-', label: 'Auto' },
  { value: 'linear', label: 'Linear' },
  { value: 'log', label: 'Log' },
  { value: 'date', label: 'Date' },
];

export const RangeTypes: SelectableValue[] = [
  { value: 'normal', label: 'Normal' },
  // { value: 'between', label: 'Between' },
  { value: 'tozero', label: 'To Zero' },
  { value: 'nonnegative', label: 'Non-Negative' },
];

export const DateRangeTypes: SelectableValue[] = [
  { value: 'dashboard', label: 'Dashboard' },
  { value: 'data', label: 'Data' },
  { value: 'manual', label: 'Manual' },
];

export const DirectionTypes: SelectableValue[] = [
  { value: 'clockwise', label: 'Clockwise' },
  { value: 'counterclockwise', label: 'Counterclockwise' },
];

export type AxisType = '-' | 'linear' | 'log' | 'date';
export type RangeType = 'normal' | 'tozero' | 'nonnegative'; // | 'between' ;
export type PolarDirectionType = 'clockwise' | 'counterclockwise' | undefined;
export type DateRangeType = 'dashboard' | 'data' | 'manual';

export interface AxisEditorSettings {
  axis: AxisItemType[];
  // enabled: boolean;
}

export interface AxisLayoutType {
  title?: { text?: string; standoff?: number };
  type?: AxisType;
  rangemode?: RangeType;
  range?: number[];
  ticksuffix?: string;
  showgrid?: boolean;
  automargin?: boolean;
  autorange?: boolean;
  zeroline?: boolean;
  direction?: PolarDirectionType;
  rotation?: number;
  dateRangeType?: DateRangeType; // not needed for plotly but for range sync
}
export interface AxisItemType {
  label: string;
  ID: string;
  layout: AxisLayoutType;
}

export interface AxisItemProps {
  axis: AxisItemType;
  setter: any;
  context: any;
}
