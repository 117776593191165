import { PanelPlugin } from '@grafana/data';
import { PlotlyOptions } from './components/types';
import { PlotlyPanel } from './components/PlotlyPanel';
import { AxisEditor } from './components/axis/AxisEditor';
import { TracesEditor } from './components/traces/TracesEditor';
import { DEFAULT_AXIS, DEFAULT_TRACE } from 'components/defaults';
import { ShapesEditor } from 'components/shapes/ShapesEditor';


export const plugin = new PanelPlugin<PlotlyOptions>(PlotlyPanel).setNoPadding().setPanelOptions((builder) => {
  return builder
    .addSelect({
      path: 'cfg.settings.type',
      defaultValue: 'scattergl',
      name: 'Type',
      category: ['Options'],
      settings: {
        options: [
          {
            value: 'scatter',
            label: 'Scatter',
          },
          {
            value: 'scattergl',
            label: 'ScatterGL',
          },
          {
            value: 'scatterpolar',
            label: 'ScatterPolar',
          },
          {
            value: 'scatter3d',
            label: 'Scatter3D',
          },
          {
            value: 'box',
            label: 'boxplot',
          },
        ],
      },
    })
    .addBooleanSwitch({
      path: 'cfg.layout.lockAxis',
      name: 'Lock axis',
      description:
        'Force same scale for x- and y-axis',
      defaultValue: false,
      category: ['Options'],
      showIf: (config) => config.cfg.settings.type === 'scatter' || config.cfg.settings.type === 'scattergl',
    })
    .addSelect({
      path: 'cfg.layout.dragmode',
      defaultValue: 'pan',
      name: 'Drag',
      category: ['Options'],
      settings: {
        options: [
          {
            value: 'zoom',
            label: 'Zoom',
          },
          {
            value: 'lasso',
            label: 'Lasso',
          },
          {
            value: 'pan',
            label: 'Pan',
          },
          {
            value: 'select',
            label: 'Select',
          },
        ],
      },
    })
    .addBooleanSwitch({
      path: 'cfg.settings.updateDashboardTimeRange',
      name: 'Update dashboard time range',
      description:
        'Changing the time range (zooming etc.) updates also the global dashboard timerange. Only has an effect when one axis is of type "date"',
      defaultValue: false,
      category: ['Options'],
    })
    .addBooleanSwitch({
      path: 'cfg.settings.displayModeBar',
      name: 'Toolbar',
      defaultValue: false,
      category: ['Options'],
    })
    .addBooleanSwitch({
      path: 'cfg.layout.showlegend',
      name: 'Legend',
      defaultValue: false,
      category: ['Options'],
    })
    .addRadio({
      path: 'cfg.layout.legend.orientation',
      defaultValue: 'h',
      name: 'Orientation',
      category: ['Options'],
      settings: {
        options: [
          {
            value: 'h',
            label: 'Horizontal',
          },
          {
            value: 'v',
            label: 'Vertical',
          },
        ],
      },
      showIf: (config) => config.cfg.layout.showlegend,
    })
    .addCustomEditor({
      name: 'Axis',
      id: 'axis',
      path: 'axisConfig',
      editor: AxisEditor,
      defaultValue: {
        axis: [DEFAULT_AXIS.get('x'), DEFAULT_AXIS.get('y')],
      },
      category: ['Options'],
    })
    .addCustomEditor({
      name: 'Traces',
      id: 'tracesConfig',
      path: 'tracesConfig',
      editor: TracesEditor,
      defaultValue: {
        traces: [DEFAULT_TRACE],
        partitionBy: '',
      },
      category: ['Traces'],
    })
    .addCustomEditor({
      name: 'Shapes',
      id: 'shapesConfig',
      path: 'shapesConfig',
      editor: ShapesEditor,
      defaultValue: {
        shapes: [],
      },
      category: ['Shapes'],
      showIf: (config) => config.cfg.settings.type === 'scatter' || config.cfg.settings.type === 'scattergl',
    })
    // .addFieldNamePicker({
    //   name: 'Picker',
    //   path: 'picker',
    // }
    // )
    ;
});

