import { AxisItemType } from './axis/types';
import { ShapeItemType } from './shapes/types';
import { TraceItemType } from './traces/types';

export const DEFAULT_TRACE: TraceItemType = {
  name: 'Trace-1',
  order: 0,
  visible: true,
  mapping: {},
  show: {
    lines: false,
    markers: true,
    extraHovertext: false,
  },
  settings: {
    mode: 'markers',
    line: {
      color: '#FF9830',
      width: 1,
      dash: 'dash',
      shape: 'linear',
    },
    marker: {
      size: 10,
      symbol: 'circle',
      color: '#73BF69',
      colorscale: 'Greys',
      showscale: false,
    },
    boxmean: true,
    boxAutoColor: true,
    boxpoints: 'outliers',
    color_option: 'solid',
    size_option: 'fixed',
  },
};

export const DEFAULT_SHAPE: ShapeItemType = {
  type: 'circle',
  order: 0,
  visible: true,
  circle: {
    radius: 1,
    Xc: 0,
    Yc: 0,
  },
  x0: 0,
  y0: 0,
  x1: 1,
  y1: 1,
  lineColor: '#19730E',
  lineWidth: 2,
  fillColor: '#96D98D',
  opacity: 0.3,
  layer: "below",
};

export const DEFAULT_AXIS: Map<string, AxisItemType> = new Map([
  [
    'x',
    {
      label: 'X-Axis',
      ID: 'x',
      layout: {
        title: { text: undefined, standoff: 20 },
        type: '-',
        rangemode: 'normal',
        range: [],
        ticksuffix: '',
        showgrid: true,
        automargin: true,
        autorange: true,
        zeroline: false,
      },
    },
  ],
  [
    'y',
    {
      label: 'Y-Axis',
      ID: 'y',
      layout: {
        title: { text: undefined, standoff: 20 },
        type: '-',
        rangemode: 'normal',
        range: [],
        ticksuffix: '',
        showgrid: true,
        automargin: true,
        autorange: true,
        zeroline: false,
      },
    },
  ],
  [
    'z',
    {
      label: 'Z-Axis',
      ID: 'z',
      layout: {
        title: { text: undefined, standoff: 20 },
        type: '-',
        rangemode: 'normal',
        showgrid: true,
        zeroline: false,
      },
    },
  ],
  [
    'r',
    {
      label: 'R-Axis',
      ID: 'r',
      layout: {
        ticksuffix: '',
        showgrid: true,
        automargin: true,
      },
    },
  ],
  [
    'theta',
    {
      label: 'Theta-Axis',
      ID: 'theta',
      layout: {
        direction: 'clockwise',
        rotation: 0,
        automargin: true,
        showgrid: true,
      },
    },
  ],
]);
