import React, { useState } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, Collapse, Field } from '@grafana/ui';

import { ShapeItem } from './ShapeItem';
import { ShapeItemType, ShapeItemTracker } from './types';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_SHAPE } from 'components/defaults';
import { PlotlyOptions } from 'components/types';

interface Props extends StandardEditorProps<PlotlyOptions["shapesConfig"]> { }

export const ShapesEditor: React.FC<Props> = ({ onChange, value, context }) => {
  const shapesConfig = value;
  const [tracker, _setTracker] = useState((): ShapeItemTracker[] => {
    if (!shapesConfig.shapes) {
      const empty: ShapeItemTracker[] = [];
      return empty;
    }
    const items: ShapeItemTracker[] = [];
    shapesConfig.shapes.forEach((value: ShapeItemType, index: number) => {
      items[index] = {
        shape: value,
        order: index,
        ID: uuidv4(),
      };
    });
    return items;
  });

  const setTracker = (v: ShapeItemTracker[]) => {
    _setTracker(v);
    // update the panel config (only the shapes themselves, not the tracker)
    const allShapes: ShapeItemType[] = [];
    v.forEach((element) => {
      allShapes.push(element.shape);
    });
    const _shapesConfig = {
      ...shapesConfig,
      shapes: allShapes,
    };
    onChange(_shapesConfig as any);
  };

  // tracks shape card collapse state
  const [isOpen, setIsOpen] = useState((): boolean[] => {
    if (!tracker) {
      const empty: boolean[] = [];
      return empty;
    }
    let size = tracker.length;
    const openStates: boolean[] = [];
    while (size--) {
      openStates[size] = false;
    }
    return openStates;
  });

  // generic move
  const arrayMove = (arr: any, oldIndex: number, newIndex: number) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  };

  const moveDown = (index: number) => {
    if (index !== tracker.length - 1) {
      arrayMove(tracker, index, index + 1);
      // reorder
      for (let i = 0; i < tracker.length; i++) {
        tracker[i].order = i;
        tracker[i].shape.order = i;
      }
      setTracker([...tracker]);
    }
  };

  const moveUp = (index: number) => {
    if (index > 0) {
      arrayMove(tracker, index, index - 1);
      // reorder
      for (let i = 0; i < tracker.length; i++) {
        tracker[i].order = i;
        tracker[i].shape.order = i;
      }
      setTracker([...tracker]);
    }
  };

  const createDuplicate = (index: number) => {
    const original = tracker[index].shape;
    const order = tracker.length;
    const aShape: ShapeItemType = {
      ...original,
      order: order,
    };
    const aTracker: ShapeItemTracker = {
      shape: aShape,
      order: order,
      ID: uuidv4(),
    };
    setTracker([...tracker, aTracker]);
    setIsOpen([...isOpen, true]);
  };

  const updateShape = (order: number, value: ShapeItemType) => {
    // find the shape by the order
    const allShapes = [...tracker];
    let indexToUpdate = 0;
    for (let i = 0; i < allShapes.length; i++) {
      if (allShapes[i].order === order) {
        indexToUpdate = i;
        break;
      }
    }
    allShapes[indexToUpdate].shape = value;
    setTracker([...allShapes]);
  };

  const removeShape = (order: number) => {
    // find the Shape by the order
    const allShapes = [...tracker];
    let removeIndex = 0;
    for (let i = 0; i < allShapes.length; i++) {
      if (allShapes[i].order === order) {
        removeIndex = i;
        break;
      }
    }
    allShapes.splice(removeIndex, 1);
    // reorder
    for (let i = 0; i < allShapes.length; i++) {
      allShapes[i].order = i;
    }
    setTracker([...allShapes]);
  };

  const addItem = () => {
    const order = tracker.length;
    const aShape: ShapeItemType = {
      ...DEFAULT_SHAPE,
      order: order,
    };
    const aTracker: ShapeItemTracker = {
      shape: aShape,
      order: order,
      ID: uuidv4(),
    };
    setTracker([...tracker, aTracker]);
    // add an opener also
    setIsOpen([...isOpen, true]);
  };


  const toggleOpener = (index: number) => {
    const toggleState = [...isOpen];
    toggleState[index] = !toggleState[index];
    setIsOpen([...toggleState]);
  };

  return (
    <>
      <Field>
        <Button fill="solid" variant="primary" icon="plus" onClick={addItem}>
          Add Shape
        </Button>
      </Field>
      {tracker &&
        tracker.map((item: ShapeItemTracker, index: number) => {
          return (
            <Collapse
              key={`collapse-item-index-${item.ID}`}
              label={item.shape.type}
              isOpen={isOpen[index]}
              onToggle={() => toggleOpener(index)}
              collapsible
            >
              <ShapeItem
                key={`shpe-item-index-${item.ID}`}
                ID={item.ID}
                shape={item.shape}
                setter={updateShape}
                remover={removeShape}
                moveUp={moveUp}
                moveDown={moveDown}
                createDuplicate={createDuplicate}
                context={context}
              />
            </Collapse>
          );
        })}
    </>
  );
};
