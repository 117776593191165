import { SelectableValue } from '@grafana/data';

export type ShapeTypeType = 'circle' | 'rect' | 'line';
export type ShapeLayerType = "below" | "above";

export interface ShapeItemType {
  type: ShapeTypeType;
  circle: {
    radius: number;
    Xc: number;
    Yc: number;
  };
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  lineColor: string;
  lineWidth: number;
  fillColor: string;
  opacity: number;
  layer: ShapeLayerType;
  order: number;
  visible: boolean;
}

export interface ShapeItemTracker {
  shape: ShapeItemType;
  order: number;
  ID: string;
}

export interface ShapeItemProps {
  shape: ShapeItemType;
  ID: string;
  setter: any;
  remover: any;
  moveUp: any;
  moveDown: any;
  createDuplicate: any;
  context: any;
}

export interface ShapeEditorSettings {
  shape: ShapeItemType[];
}

export const ShapeTypeOptions: SelectableValue[] = [
  { value: 'circle', label: 'Circle' },
  { value: 'rect', label: 'Rectangle' },
  { value: 'line', label: 'Line' },
];

export const ShapeLayerOptions: SelectableValue[] = [
  { value: 'below', label: 'Below' },
  { value: 'above', label: 'Above' },
];
