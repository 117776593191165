import React, { useState, useMemo } from 'react';

import {
  Input,
  Select,
  Field,
  FieldSet,
  Switch,
  Card,
  IconButton,
  Cascader,
  CascaderOption,
  ColorPicker,
} from '@grafana/ui';
import {
  SymbolOptions,
  MarkerSizeOptions,
  MarkerColorOptions,
  MarkerColorScaleOptions,
  LineShapeOptions,
  LineDashOptions,
  TraceItemProps,
  TraceItemType,
  TraceModeType,
  MappingType,
  BoxpointOptions,
} from './types';
import { SelectableValue } from '@grafana/data';
import { getAxisKeysByPlotType } from 'components/editorHelper';

export const TraceItem: React.FC<TraceItemProps> = (props: TraceItemProps) => {
  const [trace, _setTrace] = useState(props.trace);
  const setTrace = (value: TraceItemType) => {
    _setTrace(value);
    props.setter(trace.order, value);
  };

  const getSymbol = (symbol: string) => {
    const keys = SymbolOptions.keys();
    for (const aKey of keys) {
      if (SymbolOptions[aKey].value === symbol) {
        return SymbolOptions[aKey];
      }
    }
    return SymbolOptions[0];
  };
  const getMarkerSizeOption = (markerSize: string) => {
    const keys = MarkerSizeOptions.keys();
    for (const aKey of keys) {
      if (MarkerSizeOptions[aKey].value === markerSize) {
        return MarkerSizeOptions[aKey];
      }
    }
    return MarkerSizeOptions[0];
  };
  const getMarkerColor = (markerColor: string) => {
    const keys = MarkerColorOptions.keys();
    for (const aKey of keys) {
      if (MarkerColorOptions[aKey].value === markerColor) {
        return MarkerColorOptions[aKey];
      }
    }
    return MarkerColorOptions[0];
  };
  const getMarkerColorScale = (markerColorScale: string) => {
    const keys = MarkerColorScaleOptions.keys();
    for (const aKey of keys) {
      if (MarkerColorScaleOptions[aKey].value === markerColorScale) {
        return MarkerColorScaleOptions[aKey];
      }
    }
    return MarkerColorScaleOptions[0];
  };
  const getLineShape = (lineShape: string) => {
    const keys = LineShapeOptions.keys();
    for (const aKey of keys) {
      if (LineShapeOptions[aKey].value === lineShape) {
        return LineShapeOptions[aKey];
      }
    }
    return LineShapeOptions[0];
  };
  const getLineDash = (lineDash: string) => {
    const keys = LineDashOptions.keys();
    for (const aKey of keys) {
      if (LineDashOptions[aKey].value === lineDash) {
        return LineDashOptions[aKey];
      }
    }
    return LineDashOptions[0];
  };

  const [symbol, _setSymbol] = useState<SelectableValue<any>>(getSymbol(props.trace.settings.marker.symbol));
  const [markerSizeOption, _setMarkerSizeOption] = useState<SelectableValue<any>>(
    getMarkerSizeOption(props.trace.settings.size_option)
  );
  const [markerColor_option, _setMarkerColor_option] = useState<SelectableValue<any>>(
    getMarkerColor(props.trace.settings.color_option)
  );
  const [markerColorScale, _setMarkerColorScale] = useState<SelectableValue<any>>(
    getMarkerColorScale(props.trace.settings.marker.colorscale)
  );
  const [lineShape, _setLineShape] = useState<SelectableValue<any>>(getLineShape(props.trace.settings.line.shape));
  const [lineDash, _setLineDash] = useState<SelectableValue<any>>(getLineDash(props.trace.settings.line.dash));

  const setSymbol = (v: any) => {
    _setSymbol(v);
    setTrace({ ...trace, settings: { ...trace.settings, marker: { ...trace.settings.marker, symbol: v.value } } });
  };

  const setMarkerSizeOption = (v: any) => {
    _setMarkerSizeOption(v);
    setTrace({ ...trace, settings: { ...trace.settings, size_option: v.value } });
  };

  const setMarkerColor_option = (v: any) => {
    _setMarkerColor_option(v);
    setTrace({ ...trace, settings: { ...trace.settings, color_option: v.value } });
  };

  const setMarkerColorScale = (v: any) => {
    _setMarkerColorScale(v);
    setTrace({ ...trace, settings: { ...trace.settings, marker: { ...trace.settings.marker, colorscale: v.value } } });
  };

  const setLineShape = (v: any) => {
    _setLineShape(v);
    setTrace({ ...trace, settings: { ...trace.settings, line: { ...trace.settings.line, shape: v.value } } });
  };

  const setLineDash = (v: any) => {
    _setLineDash(v);
    setTrace({ ...trace, settings: { ...trace.settings, line: { ...trace.settings.line, dash: v.value } } });
  };

  const setLineColor = (v: string) => {
    setTrace({ ...trace, settings: { ...trace.settings, line: { ...trace.settings.line, color: v } } });
  };

  const setMarkerColor = (v: string) => {
    setTrace({ ...trace, settings: { ...trace.settings, marker: { ...trace.settings.marker, color: v } } });
  };

  const setMarkerSize = (v: number) => {
    setTrace({ ...trace, settings: { ...trace.settings, marker: { ...trace.settings.marker, size: v } } });
  };

  const setLineSize = (v: number) => {
    setTrace({ ...trace, settings: { ...trace.settings, line: { ...trace.settings.line, width: v } } });
  };

  const setBoxpoints = (v: any) => {
    setTrace({ ...trace, settings: { ...trace.settings, boxpoints: v.value } });
  };

  const updateMappings = (key: keyof MappingType, val: string) => {
    const allMappings = { ...trace.mapping };
    allMappings[key] = [val, props.fieldTypeMap.get(val) ? props.fieldTypeMap.get(val)! : ''];
    setTrace({ ...trace, mapping: allMappings });
  };

  const convertToModeString = (showMarkers: boolean, showLines: boolean) => {
    let mode = '';
    if (showMarkers) {
      mode = 'markers';
    }
    if (showLines) {
      mode = 'lines';
    }
    if (showMarkers && showLines) {
      mode = 'markers+lines';
    }
    if (mode === '') {
      mode = 'none';
    }
    return mode as TraceModeType;
  };

  const axisKeys = useMemo(() => {
    return getAxisKeysByPlotType(props.context.options.cfg.settings.type);
  }, [props.context.options.cfg.settings.type]);

  const removeItem = () => {
    props.remover(trace.order);
  };

  const toggleShowExtraHovertext = () => {
    const currentState = trace.show.extraHovertext;
    const mappings = { ...trace.mapping };
    if (currentState) {
      mappings['hovertext'] = ['', ''];
    }
    setTrace({
      ...trace,
      show: { ...trace.show, extraHovertext: !currentState },
      mapping: mappings,
    });
  };

  const toggleShowMarkers = () => {
    const currentState = trace.show.markers;
    const mode = convertToModeString(!currentState, trace.show.lines);
    setTrace({
      ...trace,
      show: { ...trace.show, markers: !currentState },
      settings: { ...trace.settings, mode: mode },
    });
  };

  const toggleShowLegend = () => {
    const currentState = trace.settings.marker.showscale;
    setTrace({
      ...trace,
      settings: { ...trace.settings, marker: { ...trace.settings.marker, showscale: !currentState } },
    });
  };

  const toggleShowLines = () => {
    const currentState = trace.show.lines;
    const mode = convertToModeString(trace.show.markers, !currentState);
    setTrace({ ...trace, show: { ...trace.show, lines: !currentState }, settings: { ...trace.settings, mode: mode } });
  };

  const toggleBoxAutoColor = () => {
    const currentState = trace.settings.boxAutoColor;
    setTrace({ ...trace, settings: { ...trace.settings, boxAutoColor: !currentState } });
  };

  const moveUp = () => {
    props.moveUp(trace.order);
  };
  const moveDown = () => {
    props.moveDown(trace.order);
  };
  const createDuplicate = () => {
    props.createDuplicate(trace.order);
  };

  return (
    <ul>
      <li>
        <Card key={`trace-card-${props.ID}-basicoptions`}>
          <Card.Meta>
            <FieldSet>
              {getBasicOptions(trace, setTrace, axisKeys, props, updateMappings, toggleShowExtraHovertext)}
              {props.context.options.cfg.settings.type === 'box' && getBoxplotOptions(
                setBoxpoints,
                setMarkerColor,
                toggleBoxAutoColor,
                trace,
              )}
            </FieldSet>
          </Card.Meta>
          {getSecondaryActions(moveUp, moveDown, trace, setTrace, createDuplicate, props, removeItem)}
        </Card>
      </li>
      {props.context.options.cfg.settings.type !== 'box' &&
        <li>
          <Card key={`trace-card-${props.ID}-markersoptions`}>
            <Card.Heading>Markers</Card.Heading>
            <Card.Meta>
              <FieldSet>
                {getMarkersOptions(
                  trace,
                  toggleShowMarkers,
                  symbol,
                  setSymbol,
                  markerColor_option,
                  setMarkerColor_option,
                  setMarkerColor,
                  props.metricHints,
                  updateMappings,
                  markerColorScale,
                  setMarkerColorScale,
                  markerSizeOption,
                  setMarkerSizeOption,
                  setMarkerSize,
                  toggleShowLegend
                )}

              </FieldSet>
            </Card.Meta>
          </Card>
        </li>
      }
      {props.context.options.cfg.settings.type !== 'box' && <li>
        <Card key={`trace-card-${props.ID}-lineoptions`}>
          <Card.Heading>Lines</Card.Heading>
          <Card.Meta>
            <FieldSet>
              {getLinesOptions(
                trace,
                toggleShowLines,
                setLineSize,
                lineShape,
                setLineShape,
                lineDash,
                setLineDash,
                setLineColor
              )}
            </FieldSet>
          </Card.Meta>
        </Card>
      </li>
      }
    </ul>
  );
};

function getBasicOptions(
  trace: TraceItemType,
  setTrace: (value: TraceItemType) => void,
  axisKeys: string[],
  props: TraceItemProps,
  updateMappings: (key: keyof MappingType, val: string) => void,
  toggleShowExtraHovertext: () => void
) {
  return (
    <>
      <Field label="Trace Name">
        <Input
          value={trace.name}
          placeholder=""
          onChange={(e) => setTrace({ ...trace, name: e.currentTarget.value })}
        />
      </Field>
      {trace.mapping &&
        axisKeys.map((key: string) => {
          const value = props.trace.mapping[key as keyof MappingType]?.at(0);
          return (
            <>
              {
                (props.context.options.cfg.settings.type !== 'box' || key !== 'x') && <Field
                  label={`${key.toUpperCase()} - Axis`}
                  key={`field-cmi-mapping-id/key-${props.ID}/${key}`}
                  style={{ minWidth: '175px' }}
                >
                  <Cascader
                    initialValue={value}
                    placeholder=""
                    options={props.metricHints}
                    onSelect={(val: string) => updateMappings(key as keyof MappingType, val)}
                  />
                </Field>
              }
              { // Use string metrics for boxplot x-axis
                props.context.options.cfg.settings.type === 'box' && key === 'x' && <Field
                  label={`${key.toUpperCase()} - Axis`}
                  key={`field-cmi-mapping-id/key-${props.ID}/${key}`}
                  style={{ minWidth: '175px' }}
                >
                  <Cascader
                    initialValue={value}
                    placeholder=""
                    options={props.stringMetricHints}
                    onSelect={(val: string) => updateMappings(key as keyof MappingType, val)}
                  />
                </Field>
              }
            </>
          );
        })}
      <Field
        label="Group by"
        key={`field-cmi-mapping-id/key-${props.ID}/groupBy`}
        style={{ minWidth: '175px' }}
      >
        <Cascader
          initialValue={props.trace.mapping['groupBy' as keyof MappingType]?.at(0)}
          placeholder=""
          options={props.stringMetricHints}
          onSelect={(val: string) => updateMappings('groupBy', val)}
        />
      </Field>
      {props.context.options.cfg.settings.type !== 'box' &&
        <>
          <Field label="Hover metric" description="Adds an extra metric to the hover-info">
            <Switch transparent={true} value={trace.show.extraHovertext} onChange={toggleShowExtraHovertext}></Switch>
          </Field>
          {trace.mapping && trace.show.extraHovertext && (
            <Field
              label="hover metric"
              key={`field-cmi-mapping-id/key-${props.ID}/hovertext`}
              style={{ minWidth: '175px' }}
            >
              <Cascader
                initialValue={props.trace.mapping['hovertext']?.at(0)}
                placeholder=""
                options={props.metricHints}
                onSelect={(val: string) => updateMappings('hovertext', val)}
              />
            </Field>
          )}
        </>
      }
    </>
  );
}

function getMarkersOptions(
  trace: TraceItemType,
  toggleShowMarkers: () => void,
  symbol: SelectableValue<any>,
  setSymbol: (v: any) => void,
  markerColor_option: SelectableValue<any>,
  setMarkerColor_option: (v: any) => void,
  setMarkerColor: (v: string) => void,
  metricHints: CascaderOption[],
  updateMappings: (key: keyof MappingType, val: string) => void,
  markerColorScale: SelectableValue<any>,
  setMarkerColorScale: (v: any) => void,
  markerSizeOption: SelectableValue<any>,
  setMarkerSizeOption: (v: any) => void,
  setMarkerSize: (v: number) => void,
  toggleShowLegend: () => void
) {
  return (
    <>
      <Field label="Show Markers" description="">
        <Switch transparent={true} value={trace.show.markers} onChange={toggleShowMarkers}></Switch>
      </Field>
      {trace.show.markers && (
        <>
          <Field label="Symbol" description="">
            <Select
              menuShouldPortal={true}
              value={symbol}
              onChange={(v) => {
                setSymbol(v);
              }}
              options={SymbolOptions}
            />
          </Field>
          <Field label="Color Mode" description="">
            <Select
              menuShouldPortal={true}
              value={markerColor_option}
              onChange={(v) => {
                setMarkerColor_option(v);
              }}
              options={MarkerColorOptions}
            />
          </Field>
          {trace.settings.color_option === 'solid' && (
            <Field label="Color" description="">
              <ColorPicker
                color={trace.settings.marker.color}
                onChange={(color) => setMarkerColor(color)}
                enableNamedColors={false}
              />
            </Field>
          )}
          {trace.settings.color_option === 'ramp' && (
            <>
              <Field label="Field" style={{ minWidth: '175px' }}>
                <Cascader
                  key={`colorfield-traceorder-${trace.order}`}
                  initialValue={trace.mapping['color']?.at(0)}
                  placeholder=""
                  options={metricHints}
                  onSelect={(val: string) => updateMappings('color', val)}
                />
              </Field>
              <Field label="Color Scale" description="">
                <Select
                  menuShouldPortal={true}
                  value={markerColorScale}
                  onChange={(v) => {
                    setMarkerColorScale(v);
                  }}
                  options={MarkerColorScaleOptions}
                />
              </Field>
            </>
          )}
          <Field label="Size Mode" description="">
            <Select
              menuShouldPortal={true}
              value={markerSizeOption}
              onChange={(v) => {
                setMarkerSizeOption(v);
              }}
              options={MarkerSizeOptions}
            />
          </Field>
          {trace.settings.size_option === 'fixed' && (
            <Field label="Size" description="">
              <Input
                type="number"
                value={trace.settings.marker.size}
                placeholder=""
                onChange={(e: any) => setMarkerSize(e.currentTarget.value)}
              />
            </Field>
          )}
          {trace.settings.size_option === 'variable' && (
            <Field label="Field" style={{ minWidth: '175px' }}>
              <Cascader
                key={`sizefield-traceorder-${trace.order}`}
                initialValue={trace.mapping['size']?.at(0)}
                placeholder=""
                options={metricHints}
                onSelect={(val: string) => updateMappings('size', val)}
              />
            </Field>
          )}
          <Field label="Legend" description="">
            <Switch transparent={true} value={trace.settings.marker.showscale} onChange={toggleShowLegend}></Switch>
          </Field>
        </>
      )}
    </>
  );
}

function getSecondaryActions(
  moveUp: () => void,
  moveDown: () => void,
  trace: TraceItemType,
  setTrace: (value: TraceItemType) => void,
  createDuplicate: () => void,
  props: TraceItemProps,
  removeItem: () => void
) {
  return (
    <Card.SecondaryActions>
      <IconButton key="moveUp" name="arrow-up" tooltip="Move Up" onClick={moveUp} />
      <IconButton key="moveDown" name="arrow-down" tooltip="Move Down" onClick={moveDown} />
      <IconButton
        key="showTrace"
        name={trace.visible ? 'eye' : 'eye-slash'}
        tooltip="Hide/Show Trace"
        onClick={() => setTrace({ ...trace, visible: !trace.visible })}
      />
      <IconButton key="copyComposite" name="copy" tooltip="Duplicate" onClick={createDuplicate} />
      {props.context.options.tracesConfig.traces.length > 1 && (
        <IconButton
          key="deleteTrace"
          variant="destructive"
          name="trash-alt"
          tooltip="Delete Trace"
          onClick={removeItem}
        />
      )}
    </Card.SecondaryActions>
  );
}

function getLinesOptions(
  trace: TraceItemType,
  toggleShowLines: () => void,
  setLineSize: (v: number) => void,
  lineShape: SelectableValue<any>,
  setLineShape: (v: any) => void,
  lineDash: SelectableValue<any>,
  setLineDash: (v: any) => void,
  setLineColor: (v: string) => void
) {
  return (
    <>
      <Field label="Show Lines" description="">
        <Switch transparent={true} value={trace.show.lines} onChange={toggleShowLines}></Switch>
      </Field>
      {trace.show.lines && (
        <>
          <Field label="Width" description="">
            <Input
              type="number"
              value={trace.settings.line.width}
              placeholder=""
              onChange={(e: any) => setLineSize(e.currentTarget.value)}
            />
          </Field>
          <Field label="Shape" description="">
            <Select
              menuShouldPortal={true}
              value={lineShape}
              onChange={(v) => {
                setLineShape(v);
              }}
              options={LineShapeOptions}
            />
          </Field>
          <Field label="Dash" description="">
            <Select
              menuShouldPortal={true}
              value={lineDash}
              onChange={(v) => {
                setLineDash(v);
              }}
              options={LineDashOptions}
            />
          </Field>
          <Field label="Color" description="">
            <ColorPicker
              color={trace.settings.line.color}
              onChange={(color) => setLineColor(color)}
              enableNamedColors={false}
            />
          </Field>
        </>
      )}
    </>
  );
}
function getBoxplotOptions(
  setBoxpoints: (v: any) => void,
  setMarkerColor: (v: any) => void,
  toggleBoxAutoColor: (v: any) => void,
  trace: TraceItemType

) {
  return (
    <>
      <Field label="Boxpoints" description="">
        <Select
          menuShouldPortal={true}
          value={trace.settings.boxpoints}
          onChange={(v) => {
            setBoxpoints(v);
          }}
          options={BoxpointOptions}
        />
      </Field>
      <Field label="Auto Color" description="">
        <Switch transparent={true} value={trace.settings.boxAutoColor} onChange={toggleBoxAutoColor}></Switch>
      </Field>
      {!trace.settings.boxAutoColor && <Field label="Color" description="">
        <ColorPicker
          color={trace.settings.marker.color}
          onChange={(color) => setMarkerColor(color)}
          enableNamedColors={false}
        />
      </Field>}
    </>
  );
}
