import { plotType } from './types';

export const getAxisKeysByPlotType = (type: plotType) => {
  let axisKeys: string[];
  switch (type) {
    case 'scatter':
    case 'scattergl':
      axisKeys = ['x', 'y'];
      break;
    case 'scatter3d':
      axisKeys = ['x', 'y', 'z'];
      break;
    case 'scatterpolar':
      axisKeys = ['r', 'theta'];
      break;
    case 'box':
      axisKeys = ['x', 'y'];
      break;
    default:
      axisKeys = [];
  }
  return axisKeys;
};
